import React from "react";
import "./Modal.css";
import { PiXCircleBold } from "react-icons/pi";

const Modal = ({ children, closeModal, showCloseButton }) => {
  return (
    <div className="custom__modal">
      {children}

      {showCloseButton ? (
        <PiXCircleBold
          className="custom_close-modal__icon"
          onClick={() => closeModal(false)}
        />
      ) : null}
    </div>
  );
};

export default Modal;
