import React from "react";
import "./Button.css";

const Button = ({ onClick, label, variant }) => {
  return (
    <button
      type="button"
      className={`custom__button ${variant ? variant : "contained"}`}
      onClick={() => onClick()}
    >
      {label ? label : "Label"}
    </button>
  );
};

export default Button;
