import React from "react";
import "./Confirmation.css";

const Confirmation = ({ title, message, label, showButton, onClick }) => {
  return (
    <div className="custom_confirmation__wrapper">
      <span className="title">{title ? title : "Enter Title"}</span>
      <span className="message">{message ? message : "Enter message"}</span>

      {showButton && (
        <button type="button" className="custom_confirmation__button" onClick={() => onClick()}>
          {label ? label : "Label"}
        </button>
      )}
    </div>
  );
};

export default Confirmation;
