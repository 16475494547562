const ObjectDataValidation = (obj) => {
  if (!obj) return false;

  const result = Object.values(obj).every((val) => {
    if (val === null || val.length === 0) return false;
    return true;
  });

  return result;
};

export default ObjectDataValidation;
