import React, { useState } from "react";
import { RiEyeOffLine, RiEyeLine } from "react-icons/ri";
import "./TextField.css";

const TextField = (props) => {
  const {
    type,
    disabled,
    labelStyle,
    label,
    inputContainerWidth,
    value,
    placeholder,
    onChange,
    onKeyDown,
    defaultValue,
    button,
    showPasswordOption,
    errorMessage,
    messageColor,
    mainContainerStyle,
    inputContainerStyle,
  } = props;

  const [togglePasswordView, setTogglePasswordView] = useState(false);

  const handleTogglePasswordView = () => {
    return setTogglePasswordView((show) => !show);
  };

  const handleInputType = () => {
    if (type === "password" && togglePasswordView === true) return "text";
    if (type === "password" && togglePasswordView === false) return "password";
    if (type === "email") return "email";

    return "text";
  };

  return (
    <div
      className="c_text-field__container"
      style={{
        ...mainContainerStyle,
        pointerEvents: disabled && "none",
        opacity: disabled && 0.4,
      }}
    >
      <label className="c_text-field__label" style={labelStyle}>
        {label}
      </label>

      <div
        className={`custom-text-field_input__wrapper ${inputContainerWidth}`}
        style={inputContainerStyle}
      >
        <input
          type={handleInputType()}
          className="c_text-field__input"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        {button}

        {showPasswordOption && (
          <button
            type="button"
            className="custom-text-field__show-password-btn"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              return handleTogglePasswordView();
            }}
          >
            {!togglePasswordView ? <RiEyeOffLine /> : <RiEyeLine />}
          </button>
        )}
      </div>

      {errorMessage && (
        <span
          style={{
            fontSize: 12,
            marginTop: 4,
            color: messageColor ? "green" : "red",
          }}
        >
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default TextField;
