import React, { useRef, useState } from "react";
import { RiUpload2Fill } from "react-icons/ri";
import "./SelectVideoFile.css";

const SelectVideoAndImage = ({ getVideoFileObject, getVideoDuration }) => {
  const [videoURL, setVideoURL] = useState(null);
  const videoRef = useRef(null);

  const handleVideoInputChange = (event) => {
    const file = event.target.files[0];
    return handleVideoFileValidation(file);
  };

  const handleVideoFileValidation = (file) => {
    if (!file) return false;

    const fileExtension = file.name.split(".")[1];

    if (
      file.type !== "video/mp4" &&
      fileExtension !== "MP4" &&
      file.type !== "video/quicktime" &&
      fileExtension !== "MOV"
    ) {
      const msg =
        "Unsupported file type. Only video files in .mp4 and .mov formats are accepted.";
      alert(msg);
      return setVideoURL(null);
    }

    const videoFileObject = file;
    const videoURL = URL.createObjectURL(videoFileObject);

    setVideoURL(videoURL);
    getVideoFileObject(videoFileObject);
  };

  const handleVideoLoadedMetadata = () => {
    const currentDuration = videoRef.current.duration;

    const convertToMinutes = () => {
      const minutes = Math.floor(currentDuration / 60);
      const remainingSeconds = currentDuration % 60;
      return `${minutes}:${remainingSeconds.toFixed(0).padStart(2, "0")}`;
    };

    getVideoDuration(convertToMinutes());
  };

  return (
    <div className="custom-video-n-image__container">
      {/* Select Video:::: */}

      <label
        className="custom-video-n-image_video__wrapper"
        htmlFor="custom-video-n-image_video__input"
      >
        {!videoURL ? (
          <div className="custom-video-n-image_video-placeholder__wrapper">
            <RiUpload2Fill className="custom-video-n-image_img-placeholder__icon" />
            Upload Video
          </div>
        ) : (
          <video
            width="100%"
            height="100%"
            controls
            ref={videoRef}
            onLoadedMetadata={handleVideoLoadedMetadata}
          >
            <source src={videoURL} type="video/mp4" />
          </video>
        )}

        <input
          id="custom-video-n-image_video__input"
          style={{ display: "none" }}
          type="file"
          accept="video/*"
          onChange={handleVideoInputChange}
        />
      </label>
    </div>
  );
};

export default SelectVideoAndImage;
